<script>
export default {
  name: 'Finished',
}
</script>

<template>
  <v-container
    class="text-center"
    fill-height
    style="height: calc(100vh - 58px);"
  >
    <v-row align="center">
      <v-col>
        <p class="font-weight-bold">
          {{ $t("pages.finished.headline") }}
        </p>
        <p>{{ $t("pages.finished.body") }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>
